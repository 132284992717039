import React, {useContext, useEffect, useRef, useState} from 'react';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import Icon from '../Icon/Icon';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
//import './PromoBanners.css';

// import required modules
import SwiperCore, {Pagination} from 'swiper';

SwiperCore.use([Pagination]);

function PromoBannersMobile(props) {
  const {items} = props;
  const clientWidth = useContext(ClientWidthContext);

  return (
    <>
      {Array.isArray(items) && items.length > 0 && (
        <>
          <div
            className={`relative flex items-center w-full h-full overflow-hidden pl-5`}
            onClick={event => {
              event.stopPropagation();
            }}>
            <Swiper
              loop={true}
              pagination={{
                type: 'progressbar',
                el: '#promo-banners-mobile-pagination',
                progressbarFillClass: `absolute left-0 top-0 bg-grey-30 w-full h-full origin-top-left`,
              }}
              spaceBetween={8}
              breakpoints={{
                320: {slidesPerView: 2.3},
                480: {slidesPerView: 2.5},
                600: {slidesPerView: 3},
                1024: {slidesPerView: 3.5},
                1279: {slidesPerView: 4},
              }}
              className={''}>
              {items.map((banner, index) => (
                <SwiperSlide key={`promo-banner-${index}`}>
                  <a href={banner.link} className={`block h-full`}>
                    <picture>
                      <source srcSet={banner.picture.webp} type="image/webp"/>
                      <source srcSet={banner.picture.avif} type="image/avif"/>
                      <img src={banner.picture.orig}
                           loading={index > 3 ? 'lazy' : 'eager'}
                           className='h-full w-full'/>
                    </picture>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div id={`promo-banners-mobile-pagination`}
               className={`relative mx-5 mt-2 w-[calc(100%-40px)] h-1 bg-grey-10 z-10`}/>
        </>
      )
      }
    </>
  );
}

export default PromoBannersMobile;