import {EventEmitter} from 'events';
import axios from 'axios';
import Analytics from '../components/analytics/Analytics';
import MultisearchTracking from '../components/analytics/MultisearchTracking';
import {DynamicRemarketing} from '../dynamicRemarketing';
import {eSTracker} from '../components/eSTracker';
import EmailWidget from '../components/emailWidget';
import SocialWidget from '../components/socialWidget';
import SvgSprite from '../components/svgSprite';
import Facebook from '../components/facebook/Facebook';
import EsputnikSubscription
  from '../components/esputnikSubscription/EsputnikSubscription';
import {AuthRegister} from '../Componentsv2/Authorization/Authorization';
import {ProductWishlist} from '../productWishlist';
import {ProductCompare} from '../productCompare';
import EsputnikApi from '../api/EsputnikApi';

// Whole site data
let someData = window.siteData || {};

const pageMeta = window.pageMeta || null;

// User session data
let sessid = someData.sessid;
// Site lang data
const lang = someData.lang;

const compareItems = someData.compareItems || null;
const isAuth = someData.isAuth;
const fuserId = someData.fuserId;
const userData = someData.userData;
const currentCityId = someData.cityId;
const currentCityName = someData.cityName;
const userId = someData.userId;
const userEsputnikId = someData.esputnikId;
const userName = someData.userName;
const userEmail = someData.userEmail;
const userPhone = someData.userPhone;

const apiBaseUrl = '/local/api/';

const langHomeDir = lang === 'ua' ? '/' : `/${lang}/`;
const langSiteDir = lang === 'ru' ? '/' : `/${lang}/`;

let userCityData = null;

EventEmitter.defaultMaxListeners = 200;
const eventEmitter = new EventEmitter();

let csrf_token = '';
let csrf_token_el = document.querySelector('meta[name="csrf-token"]');
if (csrf_token_el instanceof HTMLElement) {
  csrf_token = csrf_token_el.content;
}

const axiosInstance = axios.create({
  // `headers` are custom headers to be sent
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Bitrix-Csrf-Token': csrf_token,
    'X-Request-Lang': lang,
  },
  // `maxRedirects` defines the maximum number of redirects to follow in node.js.
  // If set to 0, no redirects will be followed.
  maxRedirects: 0,
});

const axiosConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Bitrix-Csrf-Token': csrf_token,
    'X-Request-Lang': lang,
  },
};

const analytics = new Analytics();
const multisearchTracking = new MultisearchTracking();
//Remarketing
const dynRem = new DynamicRemarketing();

// Инициализация трекеров
const esputnikTracker = new eSTracker(false);
const emailWidget = new EmailWidget();
const socialWidget = new SocialWidget();
const svgSprite = new SvgSprite();
const facebook = new Facebook(false);
const esputnikSubscription = new EsputnikSubscription(lang);

var price = $('body').data('type');

const currentUrl = window.location.pathname;

esputnikTracker.loadScript();

// Функция для установки элемента в localStorage с датой истечения
function setLocalStorageItem(key, value, expirationInDays) {
  const currentDate = new Date();
  const expirationDate = new Date(
    currentDate.getTime() + expirationInDays * 24 * 60 * 60 * 1000);

  const item = {
    value: value,
    expirationDate: expirationDate.toISOString(),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Функция для получения элемента из localStorage
function getLocalStorageItem(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const currentDate = new Date();
  if (currentDate > new Date(item.expirationDate)) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

// Таймаут на загрузку трекеров
setTimeout(() => {
  facebook.load();
  esputnikSubscription.init();

  // Проверка наличия значения emailWidget в localStorage
  const emailWidgetLoaded = getLocalStorageItem('emailWidget');
  if (!emailWidgetLoaded && !isAuth) {
    emailWidget.loadScript();
    // Установка значения emailWidget в localStorage на 2 недели
    setLocalStorageItem('emailWidget', true, 14);
  }
}, 7000);

if (price === 1) {
  socialWidget.loadScript();
}

setTimeout(() => {
  svgSprite.loadScript();
}, 2000);

if (!isAuth) {
  let timerId;

  function startTimer(sec, authRegister) {
    timerId = setInterval(function() {
      if (authRegister.showTimer(sec)) {
        sec--;
        authRegister.showTimer(sec);
        if (sec < 0) {
          clearInterval(timerId);
          authRegister.showTextContent();
        }

        authRegister.sendingRequest = false;
      }
    }, 1000);
  }

  let authRegister = new AuthRegister();

  authRegister.setCurrentMode('auth').
    setCurrentStep('searchUser').
    initTemplate().
    initStep();

  // Focus out input event
  let wrapper = authRegister.getOuter();

  wrapper.addEventListener('keyup', function(e) {
    if (e.target instanceof HTMLInputElement &&
      !e.target.classList.contains('auth_secret_code'))
      authRegister.validateInput(e.target);
  });
  // Change input event
  wrapper.addEventListener('change', function(e) {
    if (e.target instanceof HTMLInputElement &&
      !e.target.classList.contains('auth_secret_code')) authRegister.checkInput(
      e.target);
  });

  wrapper.addEventListener('submit', function(e) {
    e.preventDefault();
    if (authRegister.sendingRequest) return;
    authRegister.sendingRequest = true;
    let obj = authRegister.collectUserStepInput(); // Get current step user input value/s
    if (obj.valid) {
      switch (authRegister.getCurrentStep()) {
        case 'searchUser':
          authRegister.searchUser(obj.data);
          clearInterval(timerId);
          startTimer(15, authRegister);
          break;
        case 'authUser':
          authRegister.setAuthValue(obj.data).authUser();
          break;
        case 'fillRegisterData':
          authRegister.registerUser(obj.data);
          break;
        case 'fillForgotData':
          authRegister.resetPassword(obj.data);
          break;
      }
    }
    authRegister.sendingRequest = false;
  });

  wrapper.addEventListener('click', function(e) {
    if (e.target.dataset.hasOwnProperty('action') &&
      e.target.dataset['action'] === 'switchMode') {
      authRegister.switchMode(e.target.dataset['mode']);
      clearInterval(timerId);
      authRegister.showTextContent();
    }
    if (e.target.classList.contains('close-modal')) {
      e.preventDefault();
      authRegister.hide();
      clearInterval(timerId);
      authRegister.showTextContent();
    }
    if (e.target.classList.contains('change_param')) {
      clearInterval(timerId);
      authRegister.showTextContent();
      authRegister.setCurrentStep('searchUser').initStep();
    }

    if (e.target.classList.contains('resend_code') && !this.sendingRequest) {
      authRegister.sendingRequest = true;
      authRegister.resendSmsCode().then(
        response => {
          try {
            response = JSON.parse(response);
            // Show user message
            if ('errors' in response && response.errors.length) {
              for (var i in response.errors) {
                if (!response.errors.hasOwnProperty(i)) continue;

                if (response.errors[i].type ===
                  'number_of_requests_exceeded') authRegister.showRequestError(
                  response.errors);
              }
            }

            authRegister.hasResendSms = true;
            if (authRegister.sendingRequest) {
              clearInterval(timerId);
              startTimer(16, authRegister);
            }
          } catch (e) {
            authRegister.sendingRequest = false;
            throw new Error(e.message);
          }
        },
        () => {
          authRegister.showRequestError();
          authRegister.sendingRequest = false;
        },
      );
    }

    if (e.target.classList.contains(
      'auth_password_forgot')) authRegister.sendResetPasswordData();
    if (e.target.classList.contains('modal')) {
      authRegister.hide();
      clearInterval(timerId);
      authRegister.showTextContent();
    }
    // Show / hide password
    if (e.target.classList.contains('pass_icon') ||
      e.target.closest('.pass_icon')) {
      authRegister.switchPasswdFieldType(e);
    }
  });

  wrapper.addEventListener('input', function(e) {
    if (e.target.classList.contains('auth_secret_code')) {
      if (authRegister.sendingRequest || e.target.value.length !==
        4) return false;
      if (authRegister.checkFullPinCode(authRegister.getOuter())) {
        authRegister.sendingRequest = true;
        let obj = authRegister.collectUserStepInput(); // Get current step user input value/s
        authRegister.setAuthValue(obj.data).authUser();
      }
    }
  });

  eventEmitter.on('showAuth', mode => {
    authRegister.show();
    if (mode === 'favorite')
      authRegister.showSpecialMessage(
        authRegister.getMessage('AUTH_ADD_FAVORITES'));
  });

  eventEmitter.on('showRegister', mode => {
    authRegister.setCurrentMode('register').
      setCurrentStep('fillRegisterData').
      initStep().
      showRegiter();
  });

}

/**
 * старая логика wishlist / compare
 *
 */
let productWishlist = new ProductWishlist();
let productCompare = new ProductCompare();

eventEmitter.on('updateCompareListState', (compareButtonsList) => {
  // Load updated items list
  if (compareButtonsList instanceof Array && compareButtonsList.length) {
    compareButtonsList.forEach(button => {
      if (!button.parentNode.classList.contains('active')) {
        button.parentNode.classList.add('active');
      }
    });
  }
});

/**
 * старая логика wishlist / compare
 *
 */

const esputnikApi = new EsputnikApi();

const underlineClass = 'decoration-blue-primary underline-offset-4 underline'
const hoverUnderlineClass = 'hover:decoration-blue-primary hover:underline-offset-4 hover:underline'

const hoverBtnClass = 'hover:bg-blue-hover'

export {
  hoverBtnClass,
  underlineClass,
  hoverUnderlineClass,
  esputnikApi,
  productWishlist,
  productCompare,
  eventEmitter,
  facebook,
  analytics,
  sessid,
  lang,
  isAuth,
  userData,
  fuserId,
  currentCityId,
  currentCityName,
  userId,
  userEsputnikId,
  userName,
  userEmail,
  userPhone,
  dynRem,
  esputnikTracker,
  axiosConfig,
  multisearchTracking,
  userCityData,
  axiosInstance,
  price,
  compareItems,
  apiBaseUrl,
  langHomeDir,
  langSiteDir,
  pageMeta,
};