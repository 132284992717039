import React, {useContext, useEffect, useRef, useState} from 'react';
import Lang from '../Lang/Lang';
import {ClientWidthContext} from '../Context/ApplicationContextProvider';
import {
  axiosInstance,
  eventEmitter,
  sessid,
  lang,
  apiBaseUrl, underlineClass, hoverUnderlineClass, hoverBtnClass,
} from '../../common/baseData';

function AbandonedCart(props) {
  const clientWidth = useContext(ClientWidthContext);

  const [state, setState] = useState(window.abandonedCartData || {
    items: null,
    price: null,
    titleItemsCountLang: '',
    firstItem: null,
    orderLink: '#',
  });

  const url = `${apiBaseUrl}basket/abandoned`;

  // basket update event
  useEffect(() => {
    eventEmitter.on('basketUpdated', getBasketState);

    return () => {
      eventEmitter.off('basketUpdated', getBasketState);
    }
  }, []);

  // Loading component data
  function getBasketState() {
    axiosInstance.post(url).then(response => response.data).then(response => {
      setState({
        ...state,
        firstItem: response.firstItem,
        items: response.items,
        price: response.price,
        titleItemsCountLang: response.titleItemsCountLang,
        orderLink: response.orderLink,
      });
    });
  }

  const isDesktop = clientWidth >= 1280;
  const heedClampTitle = Array.isArray(state.items) && state.items.length > 2;
  const desktopClass = `${heedClampTitle
    ? 'max-w-[116px] line-clamp-2 overflow-hidden desktop:w-24 desktop-max:w-28'
    : ''} font-medium text-[12px] leading-[18px] text-grey-90 ${hoverUnderlineClass}`;

  return (
    <div className={`${!Array.isArray(state.items) || !state.items.length
      ? 'hidden'
      : ''} relative mb-5 mx-5 p-3 desktop:mx-0 border-grey-10 border border-solid flex justify-between items-center desktop:border-none desktop:p-0`}>
      {
        Array.isArray(state.items) && state.items.length && (
          <>
            <ul
              className="desktop:order-2 flex gap-2 overflow-x-scroll scroll-smooth snap-x snap-mandatory overscroll-x-none invisible-scrollbar cursor-col-resize">
              {
                isDesktop ?
                  state.items.map((item, index) => (
                    <li key={`mobile-abandoned-item-${item.recId}`}
                        className="flex gap-2 items-center">
                        <span
                          className="flex items-center justify-center border border-solid border-grey-10 h-10 w-10">
                          <img src={item.image}
                               width="32px"
                               height="32px"
                               loading="eager"/>
                        </span>
                      <a href={item.link}
                         className={desktopClass}>
                        {item.name}
                      </a>
                    </li>
                  )) :
                  state.firstItem && (
                    <li
                      key={'mobile-abandoned-item'}
                      className="flex items-center justify-center border border-solid border-grey-10 h-10 w-10">
                      <img src={state.firstItem.image}
                           width="32px"
                           height="32px"
                           loading="eager"/>
                    </li>
                  )
              }
            </ul>
            <span
              className="text-grey-90 font-medium text-[14px] leading-[21px] desktop:text-[13px] desktop:leading-[20px] desktop:order-1 shrink-0 desktop:mr-[50px]">
                {state.titleItemsCountLang}
            </span>
            <a href={state.orderLink}
               className={`relative bg-blue-primary px-4 text-white text-[14px] leading-[21px] px-4 py-3 border-0 rounded-2 desktop:order-3 desktop:ml-2 ${hoverBtnClass}`}>
              {
                isDesktop && state.items.length > 3 && (
                  <span key={'block-overlay'}
                        className={`absolute top-0 left-[-60px] w-[60px] h-full z-2`}
                        style={{background: 'linear-gradient(to right, transparent, #fff)'}}/>
                )
              }
              <span>{Lang.getMessage('makeOrder')}</span>
            </a>
          </>
        )
      }
    </div>
  );
}

export default AbandonedCart;