import '../../common/offerColors.css';
import '../../common/style.css';
import React, {
  Suspense,
  useEffect,
  useState,
  useContext, useRef, lazy,
} from 'react';
import Icon from './../Icon/Icon';
import StaticMenu from '../StaticMenu';
import UserData from '../UserData/UserData';
import CitySwitch from '../CitySwitch/CitySwitch';
import LangSwitch from '../LangSwitch/LangSwitch';
import {
  eventEmitter,
  lang,
  esputnikTracker,
  analytics,
  isAuth,
  langHomeDir, hoverBtnClass,
} from '../../common/baseData';
import Basket from '../../Componentsv2/Basket/Basket';
import Lang from '../Lang/Lang';
import Banner from './Banner';
import SecondLine from './SecondLine';
import AppLogo from '../Icon/Icons/appIcon.svg';
import StickyAppLogo from '../Icon/Icons/stickyAppIcon.svg';
import FirstLine from './FirstLine';
import SearchForm from '../SearchForm/SearchForm';
import {
  ModalContext, ModalDispatchContext, ClientWidthContext, SiteContext,
} from '../Context/ApplicationContextProvider';
import TapBar from '../TapBar/TapBar';
import LoaderOverlay from '../LoaderOverlay/LoaderOverlay';
import ScrollTop from './ScrollTop';
import SearchResult from '../SearchForm/SearchResult';
import SecondLineMobileSticky from './SecondLineMobileSticky';
import SecondLineDesktop from './SecondLineDesktop';
import SecondLineMobile from './SecondLineMobile';
import SearchMobile from '../SearchForm/SearchMobile';

const GoogleAuthozation = lazy(
  () => import('../GoogleAuthorization/GoogleAuthozation'));

function Header(props) {
  const {page} = {...props};

  const modals = useContext(ModalContext);
  const dispatchModal = useContext(ModalDispatchContext);
  const clientWidth = useContext(ClientWidthContext);
  const siteSettings = useContext(SiteContext);

  const basketInitial = window.basketInitial || {};
  const [sticky, setSticky] = useState(false);

  const showOverlay = Array.isArray(modals) && modals.length > 0;

  const [headerBannerData, setHeaderBannerData] = useState(
    window.headerBanner || null);

  const [showTopBanner, setShowTopBanner] = useState(
    headerBannerData !== null &&
    (clientWidth < 1024 && window.sessionStorage.getItem('mobileTopBanner') !==
      'hide' || clientWidth > 1024),
  );

  const isMobile = clientWidth <= 1024;

  useEffect(() => {
    eventEmitter.on('EsputnikLoaded', () => {
      if (page === 'Main') {
        esputnikTracker.MainPage();
      }
    });
  }, [page]);

  useEffect(() => {

    if (window.sessionStorage.hasOwnProperty('select_item_category')) {
      let category_select_item_id = window.sessionStorage.getItem(
        'select_item_category');
      if (!isNaN(Number(category_select_item_id))) {
        let item_id = Number(category_select_item_id);
        analytics.selectItem(item_id, 'Category Products', 'category_products').
          then(response => {
            console.info(response, item_id);
          }).
          catch(reason => {
            console.error(reason);
          });
        window.sessionStorage.removeItem('select_item_category');
      }
    }
  }, []);

  return (
    <>
      {
        showTopBanner && (
          <Banner {...headerBannerData} setShow={setShowTopBanner}/>
        )
      }
      <header className={`relative flex flex-col justify-center items-center bg-white w-full`}>
        <FirstLine>
          <a href={langHomeDir}
             className={`flex items-center shrink-1 w-full h-10 desktop:h-10.5 max-w-[149px] desktop:max-w-[190px]`}>
            <img src={siteSettings.desktopLogo} className={`w-full`} width={'100%'} height={'auto'}/>
          </a>
          <StaticMenu/>
          <div className="flex items-center desktop:gap-6">
            {
              clientWidth >= 1280 ? // desktop
                <>
                  <div className="flex items-center">
                    <a href={`tel:${'0800204402'}`}
                     className="h-10 w-10 flex items-center justify-center">
                    <Icon name="phone-icon"
                          className="w-[24px] h-[25px]"/>
                    </a>
                    {
                      isAuth && (
                        <div id="app-inbox"
                             className={'app-inbox w-10 h-10 flex items-center justify-center'}/>
                      )
                    }
                  </div>
                  <CitySwitch lang={lang}/>
                  <LangSwitch lang={lang}/>
                </> :  // mobile
                <UserData clientWidth={clientWidth}/>
            }
          </div>
        </FirstLine>
        <SecondLine sticky={sticky} page={page} setSticky={setSticky}>
          {
            clientWidth >= 1280
              ? <SecondLineDesktop
                sticky={sticky}
                showTopBanner={showTopBanner}/> :
              sticky ?
                <SecondLineMobileSticky page={page}/> :
                <SecondLineMobile showTopBanner={showTopBanner}
                                  page={page}/>
          }
        </SecondLine>

        {/** Блок со скрытиями компонентами **/}
        <Basket page={'site'} initialState={basketInitial}/>
        {
          !isAuth && (
            <Suspense fallback={<></>}>
              <GoogleAuthozation/>
            </Suspense>
          )
        }
        <TapBar/>
        {
          modals.includes('mobileSearch') && isMobile && (
            <SearchMobile/>
          )
        }
        <LoaderOverlay/>
        {clientWidth >= 1024 && (<ScrollTop sticky={sticky}/>)}
        {/** Блок со скрытиями компонентами **/}
      </header>
      {
        clientWidth >= 1024 && (
          <section id={'overlay'}
                   className={`${showOverlay
                     ? ''
                     : 'hidden'} transition-display ease-linear duration-700 absolute z-10 top-0 left-0 right-0 bottom-0 bg-grey-overlay w-screen h-full cursor-all-scroll`}
                   onClick={() => {
                     dispatchModal({type: 'hide', modalType: 'all'});
                   }}/>
        )
      }
    </>
  );
}

export default Header;